export const translate = {
    'en': {
        home: 'Home',
        streaming: 'Streaming',
        categories: 'Categories',
        history: 'History',
        more: 'More',
        placeholder: 'Title / Actor / Director',
        cancel: 'Cancel',
        search: 'Search',
        searchHistory: 'Search History',
        researchPopularity: 'Research popularity',
        play: 'Play',
        introduction: 'Introduction',
        download: 'Download',
        feedback: 'Feedback',
        delHistoryTip: 'Are you sure you want to clear your search history?',
        confirm: 'Confirm',
        all: 'All',
        movie: 'Movies',
        series: 'TV Series',
        actor: 'Lead actor',
        director: 'Director',
        releaseTime: 'Release time',
        pullToRefresh: 'Drop down to refresh ...',
        loosing: 'Release to refresh ..',
        loading: 'Loading ...',
        downloadTip: 'Features under development ...',
        contentProblem: 'Content issue',
        playbackProblem: 'Playback problem',
        downloadProblem: 'Download problem',
        abnormalExit: 'Crashes during playback',
        unableLogin: 'Unable to log in',
        finish: '—— END ——',
        moreWonderful: 'More Wonderful',
        downloading: 'Features under development . . .',
        updateTo: 'Update to',
        selectEpisode: 'Selected Episodes',
        sendButton: 'Submit',
        feedbackErrorTip: 'Please enter feedback!',
        selectedAll: 'Select all',
        deselect: 'Deselect',
        delete: 'Delete',
        playDataLoading: 'Loading, please wait...',
        searchEmptyTip: 'Search content cannot be empty',
        genres: 'Genres',
        user: 'User',
        contact: 'Contact Us',
        playError: 'Please do not use other browsers to open the link. Please return to our application to open the link.',
        question_title:'Common Problem'
    },
    'pt': {
        home: 'Início',
        streaming: 'Ranking',
        categories: 'Categoria',
        history:'História',
        more: 'Mais',
        placeholder: 'Título / Ator / Diretor',
        cancel: 'Cancelar',
        search: 'Pesquisar',
        searchHistory: 'Histórico de pesquisa',
        researchPopularity: 'Popularidade da pesquisa',
        play: 'Assistir',
        introduction: 'Introdução',
        download: 'Baixar',
        feedback: 'Feedback',
        delHistoryTip: 'Você tem certeza de que quer eliminar sua história?',
        confirm: 'Confirmado',
        all: 'Todos',
        movie: 'Films',
        series: 'Séries',
        actor: 'Ator',
        director: 'Diretor',
        releaseTime: 'Hora de lançamento',
        pullToRefresh: 'Libertar para refrescar',
        loosing: 'Solte para atualizar ..',
        loading: 'Carregando...',
        downloadTip: 'A função de download está atualmente em desenvolvimento',
        contentProblem: 'Temas de conteúdo',
        playbackProblem: 'Há um problem a tocando o vídeo',
        downloadProblem: 'Problema de descarga',
        abnormalExit: 'Flashback quando tocando um filme',
        unableLogin: 'Não foi possível entrar na conta',
        finish: '—— FIM ——',
        moreWonderful: 'Mais emocionantes',
        downloading: 'A função de download está atualmente em desenvolvimento',
        updateTo: 'Actualizar para',
        selectEpisode: 'Episódios selecionados',
        sendButton: 'Enviar',
        feedbackErrorTip: 'Por favor, forneça feedback!',
        selectedAll: 'Escolha Tudo',
        deselect: 'Cancelar toda a seleção',
        delete: 'Eliminar',
        playDataLoading: 'Cargando, por favor esperem ...',
        searchEmptyTip: 'O conteúdo da busca não pode estar vazio',
        genres: 'Gênero',
        user: 'Usuário',
        contact: 'Contate-nos',
        playError: 'Não utilize outro navegador para abrir o link. É necessário voltar ao nosso aplicativo para o abrir.'
    },
    'es' :{
        home: 'Inicio',
        streaming: 'Streaming',
        categories:'Categorías',
        history:'Historial',
        more: 'Más',
        placeholder: 'Título / Actor / Director',
        cancel: 'Cancelar',
        search: 'Buscar',
        searchHistory: 'Historial de búsqueda',
        researchPopularity: 'Popularidad de la investigación',
        play: 'Start',
        introduction: 'Introducción',
        download: 'Descargar',
        feedback: 'Comentarios',
        delHistoryTip: '¿Está seguro de que desea borrar su historial de búsqueda?',
        confirm: 'Confirmar',
        all: 'Todos',
        movie: 'Películas',
        series: 'Series',
        actor: 'Actor',
        director: 'Director',
        releaseTime: 'Hora de lanzamiento',
        pullToRefresh: 'Desplegar para actualizar ...',
        loosing: 'Soltar para actualizar ...',
        loading: 'Cargando ...',
        downloadTip: 'Funciones en desarrollo ...',
        contentProblem: 'Problema de contenido',
        playbackProblem: 'Problema de reproducción',
        downloadProblem: 'Problema de descarga',
        abnormalExit: 'Error de carga',
        unableLogin: 'No se puede iniciar sesión',
        finish: '—— FIN ——',
        moreWonderful: 'Más maravilloso',
        downloading: 'Funciones en desarrollo . . .',
        updateTo: 'Actualizar a',
        selectEpisode: 'Episodios seleccionados',
        sendButton: 'Enviar',
        feedbackErrorTip: '¡Por favor, introduzca sus comentarios!',
        selectedAll: 'Seleccionar todo',
        deselect: 'Deseleccionar',
        delete: 'Eliminar',
        playDataLoading: 'Cargando, por favor espere...',
        searchEmptyTip: 'El contenido de búsqueda no puede estar vacío',
        genres: 'Géneros',
        user: 'Usuario',
        contact: 'Contáctanos',
        playError: 'No use otros navegadores para abrir enlaces. Por favor, regrese a nuestra aplicación para abrir el enlace.'
    },
    'hi': {
        home: 'घर',
        streaming: 'स्ट्रीमिंग',
        categories:'श्रेणियाँ ',
        history:'इतिहास ',
        more: 'अधिकअधिक',
        placeholder: 'शीर्षक / अभिनेता / निर्देशक',
        cancel: 'रद्द करें',
        search: 'खोज ',
        searchHistory: 'खोज इतिहास',
        researchPopularity: 'अनुसंधान की लोकप्रियता',
        play: 'खेलना',
        introduction: 'परिचय',
        download: 'डाउनलोड करें',
        feedback: 'प्रतिक्रिया ',
        delHistoryTip: 'Are you sure you want to clear your search history?',
        confirm: 'Confirm',
        all: 'All',
        movie: 'Movies',
        series: 'TV Series',
        actor: 'Lead actor',
        director: 'Director',
        releaseTime: 'Release date',
        pullToRefresh: 'Drop down to refresh ...',
        loosing: 'Release to refresh ..',
        loading: 'Loading ...',
        downloadTip: 'Features under development ...',
        contentProblem: 'Content issue',
        playbackProblem: 'Playback problem',
        downloadProblem: 'Download problem',
        abnormalExit: 'Crashes during playback',
        unableLogin: 'Unable to log in',
        finish: '—— FIM ——',
        moreWonderful: 'अधिक अद्भुत',
        downloading: 'Features under development . . .',
        updateTo: 'Update to',
        selectEpisode: 'Selected Episodes',
        sendButton: 'Submit',
        feedbackErrorTip: 'Please enter feedback!',
        selectedAll: ' सभी चुनें',
        deselect: 'चयन रद्द करें',
        delete: 'हटाएं',
        playDataLoading: 'Loading, please wait...',
        searchEmptyTip: 'Search content cannot be empty',
        genres: 'Genres',
        user: 'उपयोगकर्ता',
        contact: 'हमसे संपर्क करें',
        playError: 'कृपया लिंक खोलने के लिए अन्य ब्राउज़र का उपयोग न करें। कृपया लिंक खोलने के लिए हमारे ऐप पर वापस लौटें',
        question_title: 'सामान्य समस्या'
    },
    'ta': {
        home: 'வீடு',
        streaming: 'நேரடிநேரடி ',
        categories:'பகுப்புகள் ',
        history:'வரலாறு',
        more: 'மேலும்',
        placeholder: 'தலைப்பு / நடிகர் / இயக்குநர்',
        cancel: 'ரத்து செய்',
        search: 'தேடு',
        searchHistory: 'தேடல் வரலாறு',
        researchPopularity: 'ஆராய்ச்சி புகாரளி',
        play: 'விளை',
        introduction: 'அறிமுகம்',
        download: 'பதிவிறக்க',
        feedback: 'கருத்து',
        delHistoryTip: 'Are you sure you want to clear your search history?',
        confirm: 'Confirm',
        all: 'All',
        movie: 'Movies',
        series: 'TV Series',
        actor: 'Lead actor',
        director: 'Director',
        releaseTime: 'Release date',
        pullToRefresh: 'Drop down to refresh ...',
        loosing: 'Release to refresh ..',
        loading: 'Loading ...',
        downloadTip: 'Features under development ...',
        contentProblem: 'Content issue',
        playbackProblem: 'Playback problem',
        downloadProblem: 'Download problem',
        abnormalExit: 'Crashes during playback',
        unableLogin: 'Unable to log in',
        finish: '—— FIM ——',
        moreWonderful: 'மேலும் அருமை',
        downloading: 'Features under development . . .',
        updateTo: 'Update to',
        selectEpisode: 'Selected Episodes',
        sendButton: 'Submit',
        feedbackErrorTip: 'Please enter feedback!',
        selectedAll: 'அனைத்து',
        deselect: 'தேர்வையிடாதது',
        delete: 'அழிக்கவும்',
        playDataLoading: 'Loading, please wait...',
        searchEmptyTip: 'Search content cannot be empty',
        genres: 'Genres',
        user: 'பயனர் ',
        contact: 'எங்களைத் தொடர்புகொள்ளவும்',
        playError: 'இணைப்பைத் திறக்க மற்ற உலாவிகளைப் பயன்படுத்த வேண்டாம். இணைப்பைத் திறக்க, எங்கள் பயன்பாட்டிற்குத் திரும்பவும்',
        question_title: 'सामान्य समस्या'
    },
    'te':{
        home: 'ఇంటి',
        streaming:'స్ట్రీమింగ్ ',
        categories:'వర్గాలు',
        history:'చరిత్ర',
        more: 'మరింత',
        placeholder: 'శీర్షిక / నటుడు / దర్శకుడు',
        cancel: 'రద్దు చేయండి',
        search: 'వెతికి',
        searchHistory: 'శోధన చరిత్ర',
        researchPopularity: 'అధ్యయన ప్రముఖత',
        play: 'ఆడు',
        introduction: 'పరిచయం',
        download: 'దింపు',
        feedback: 'ప్రతిక్రియ ',
        delHistoryTip: 'Are you sure you want to clear your search history?',
        confirm: 'Confirm',
        all: 'All',
        movie: 'Movies',
        series: 'TV Series',
        actor: 'Lead actor',
        director: 'Director',
        releaseTime: 'Release date',
        pullToRefresh: 'Drop down to refresh ...',
        loosing: 'Release to refresh ..',
        loading: 'Loading ...',
        downloadTip: 'Features under development ...',
        contentProblem: 'Content issue',
        playbackProblem: 'Playback problem',
        downloadProblem: 'Download problem',
        abnormalExit: 'Crashes during playback',
        unableLogin: 'Unable to log in',
        finish: '—— FIM ——',
        moreWonderful: 'మరింత అద్భుతమైన',
        downloading: 'Features under development . . .',
        updateTo: 'Update to',
        selectEpisode: 'Selected Episodes',
        sendButton: 'Submit',
        feedbackErrorTip: 'Please enter feedback!',
        selectedAll: 'అన్ని ఎంపిక చేసుకోండి',
        deselect: 'ఎంపిక తొలగించండి',
        delete: 'తొలగించండి',
        playDataLoading: 'Loading, please wait...',
        searchEmptyTip: 'Search content cannot be empty',
        genres: 'Genres',
        user: 'వినియోగదారు ',
        contact: 'మమ్మల్ని సంప్రదించండి',
        playError: 'దయచేసి లింక్‌ని తెరవడానికి ఇతర బ్రౌజర్‌లను ఉపయోగించవద్దు. లింక్‌ని తెరవడానికి దయచేసి మా యాప్‌కి తిరిగి వెళ్లండి',
        question_title: 'सामान्य समस्या'
    },
    'ml' : {
        home: 'വീട്വീട്',
        streaming:'വിഭാഗങ്ങൾ',
        categories:'വിഭാഗങ്ങൾ',
        history:'ചരിത്രം',
        more: 'കൂടുതൽ',
        placeholder: 'തലക്കെട്ട് / നടന്‍ / സംവിധായകന്‍',
        cancel: 'റദ്ദാക്കുക',
        searchHistory: 'തിരച്ചില്‍ ചരിത്രം',
        researchPopularity: 'പഠനത്തിന്റെ പ്രമുഖത',
        play: 'കളിക്കുക',
        introduction: 'പ്രവേശനം',
        download: 'దింపు',
        feedback: 'ప్రతిక్రియ ',
        delHistoryTip: 'Are you sure you want to clear your search history?',
        confirm: 'Confirm',
        all: 'All',
        movie: 'Movies',
        series: 'TV Series',
        actor: 'Lead actor',
        director: 'Director',
        releaseTime: 'Release date',
        pullToRefresh: 'Drop down to refresh ...',
        loosing: 'Release to refresh ..',
        loading: 'Loading ...',
        downloadTip: 'Features under development ...',
        contentProblem: 'Content issue',
        playbackProblem: 'Playback problem',
        downloadProblem: 'Download problem',
        abnormalExit: 'Crashes during playback',
        unableLogin: 'Unable to log in',
        finish: '—— FIM ——',
        moreWonderful: 'കൂടുതൽ',
        downloading: 'Features under development . . .',
        updateTo: 'Update to',
        selectEpisode: 'Selected Episodes',
        sendButton: 'Submit',
        feedbackErrorTip: 'Please enter feedback!',
        selectedAll: 'എല്ലാം',
        deselect: 'റദ്ദാക്കുക',
        delete: 'മായ്ക്കുക',
        playDataLoading: 'Loading, please wait...',
        searchEmptyTip: 'Search content cannot be empty',
        genres: 'Genres',
        user: 'ഉപയോക്താവ് ',
        contact: 'ഞങ്ങളെ ബന്ധപ്പെടൂ',
        playError: 'ലിങ്ക് തുറക്കാൻ ദയവായി മറ്റ് ബ്രൗസറുകൾ ഉപയോഗിക്കരുത്. ലിങ്ക് തുറക്കാൻ ഞങ്ങളുടെ ആപ്പിലേക്ക് മടങ്ങുക',
        question_title: 'सामान्य समस्या'
    }
}
