import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '@/views/loginRegister/login.vue'
import register from '@/views/loginRegister/register.vue'
import banner from '@/components/lnicio/banner'
Vue.use(VueRouter)

const routes = [
  //测试banner
  {
    path: '/banner',
    name: 'Banner',
    meta: {
      title: '登录'
    },
    component: banner
  },
  //登录
  {
    path: '/login',
    name: 'Login',
    meta: {
      title: '登录'
    },
    component: login
  },
    //注册
  {
    path: '/register',
    name: 'Register',
    meta: {
      title: '注册'
    },
    component: register
  },
  //首页的更多
  {
    path: '/more',
    name: 'More',
    component: () => import('@/components/lnicio/more')
  },
  {
    path: '/',
    redirect: '/lnicio',
  },
  {
    path: '/lnicio',
    name: 'lnicio',
    meta: {
      keepAlive: true,
    },
    component: () => import('@/views/lnicio/index')
  },
  {
    path: '/streaming',
    name: 'streaming',
    meta: {
      keepAlive: true,
    },
    component: () => import('@/views/Streaming/index')
  },
  {
    path: '/classificacao',
    name: 'classificacao',
    meta: {
      keepAlive: true,
    },
    component: () => import('@/views/Classificacao/index')
  },
  {
    path: '/meu',
    name: 'Meu',
    component: () => import('@/views/Meu/index')
  },
  {
    path: '/pay',
    name: 'Pay',
    component: () => import('@/views/pay')
  },

  //播放页
  {
    path: '/play',
    name: 'Play',
    component: () => import('@/views/play/no_play')
  },
  //观看历史
  {
    path: '/ViewingHistory',
    name: 'ViewingHistory',
    component: () => import('@/components/register/ViewingHistory')
  },
  //下载
  {
    path: '/meuDownload',
    name: 'MeuDownload',
    component: () => import('@/components/register/download')
  },
  //反馈
  {
    path: '/feedback',
    name: 'Opiniao',
    component: () => import('@/components/register/opiniao')
  },{
    path: '/feedback/record',
    name: 'FeedbackRecord',
    component: () => import('@/components/register/fankuiRecord')
  },
  //我的板块---板块
  {
    path: '/configurar',
    name: 'Configurar',
    component: () => import('@/components/register/configurar')
  },
  {
    path: '/history',
    name: 'History',
    component: () => import('@/views/History/index')
  },
  {
    path: '/user',
    name: 'User',
    component: () => import('@/views/User/index')
  },
  //搜索
  {
    path: '/search',
    name: 'Search',
    redirect: '/search/historyRecommend', // 重定向
    component: () => import('@/views/searchTerms/index'),
    children:[
      {
        path: 'historyRecommend',
        name: 'HistoryRecommend',
        component: () => import('@/components/searchTerms/historyRecommend')
      },
      {
        path: 'searchRecommend',
        name: 'SearchRecommend',
        component: () => import('@/components/searchTerms/searchRecommend')
      },
      {
        path: 'recommendList',
        name: 'RecommendList',
        component: () => import('@/components/searchTerms/recommendList')
      },
    ]
  },
  {
    path: '/question',
    name: 'question',
    component: () => import('@/views/question/index')
  },
]

const router = new VueRouter({
  // mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  document.title = 'freecine' // 如果路由中有标题，则使用路由中的标题；否则使用默认标题
  next()
})
// router.beforeEach((to, from, next) => {
//   // to要去的页面 from从哪儿来 next通过(放行)
//   const token = localStorage.getItem('token')
//   // console.log(token)
//   if (token) {
//     next()
//   } else {
//     if (to.meta.requireAuth) {
//       next('/login')
//     } else {
//       next()
//     }
//   }
// })
export default router
